import { Layout, AutoComplete, Input, Typography } from 'antd';
import React from 'react';
import { useState } from "react";
const { Header } = Layout;
import { SearchOutlined } from "@ant-design/icons";
import Link from 'next/link';
import { useRouter } from 'next/navigation'
const { Text } = Typography;

import type { SelectProps } from 'antd/es/select';

const getRandomInt = (max: number, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;

const searchResult = (query: string) =>
  new Array(getRandomInt(5))
    .join('.')
    .split('.')
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <Link href="/stocks">
          <Text>{category}</Text>
        </Link>
      
        ),
      };
    });
export default function HeaderLayout() {
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const router = useRouter()
  const handleSearch = (value: string) => {
    setOptions(value ? searchResult(value) : []);
  };

  const onSelect = (value: string) => {
    console.log('onSelect', value);
  };
    return (
        <Header
            className="site-layout-background"
            style={{
                padding: 0,
            }}
        >
            <AutoComplete style={{ width: "100%", maxWidth: "550px" }} 
            filterOption={false}
            options={options}
            onSearch={handleSearch}
            onSelect={() => router.push('/stocks')}
            >
                <Input size="large" placeholder="Search stock name or symbol" suffix={<SearchOutlined />} />
            </AutoComplete>
        </Header>

    )
}
