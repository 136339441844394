import { Layout } from 'antd';
import React from 'react';
import HeaderLayout from './header';
const { Content } = Layout;


export default function AppLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Layout className="site-layout">
        <HeaderLayout />
          <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
              }}
          >
            {children}
          </Content>
          </Layout>
  )
}