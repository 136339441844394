"use client";

import AppLayout from '@/layouts/applayout';
import withTheme from '../../theme';
import React from 'react';
import { Table, Tag, Spin, Alert, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import useSWR from 'swr'

async function fetcher(url: string): Promise<any> {
    return fetch(url).then(res => res.json());
}

interface DataType {
    key: string;
    symbol: string;
    title: number;
    description: string;
    tags: string[];
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render: (text) => <a href={'/stocks/' + text}>{text}</a>,
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, { tags }) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    }
];


const Home = function Home() {
    const { data, error } = useSWR(`${process.env.API_PREFIX}/gold_list`, fetcher)

    if (error) {

        return (
            <AppLayout>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert message="Error Loading Data" type="error" />
                </Space>
            </AppLayout>

        )
    }
    if (!data) {
        return <Spin fullscreen spinning />
    }

    return (
        <AppLayout>
            <Table columns={columns} dataSource={data} />
        </AppLayout>

    )
}


const HomePage = () => {
    return withTheme(<Home />);
}

export default HomePage;
